import { materialsActions } from "api/manufacturing/materials/actions";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiCached } from "components/miloDesignSystem/atoms/icons/MdiCached";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useToggle } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { ManualMaterialStockUpdate } from "../../manualMaterialStockUpdate/ManualMaterialStockUpdate";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const PanelHeader = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { close } = useDrawer("materialsDemandAndAvailability");
  const { data: materialDemand } = materialsActions.useMaterial(panelId, {});
  const { isTriggerElementHidden } = useScrollableContext();
  const manualMaterialStockUpdateModal = useToggle();

  if (!materialDemand) return null;

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper>
            <Typography fontSize="16" fontWeight="600" noWrap>
              {EMPTY_VALUE}
            </Typography>
          </SlidingHeaderWrapper>
        ) : (
          <div />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          <div className="d-flex align-items-center gap-2">
            <Button
              className="text-uppercase"
              onClick={manualMaterialStockUpdateModal.open}
              size="small"
              startIcon={MdiCached}
              variant="gray"
            >
              ręcznie zmień stan
            </Button>
            <Button className="text-uppercase" disabled size="small" variant="gray">
              zamów
            </Button>
          </div>
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {manualMaterialStockUpdateModal.isOpen && (
        <ManualMaterialStockUpdate
          close={manualMaterialStockUpdateModal.close}
          materialDemand={materialDemand}
        />
      )}
    </>
  );
};
