import {
  ManualMaterialStockUpdatePayload,
  MaterialStockUpdateDirection,
} from "api/manufacturing/materials/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiRadioButtonChecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonChecked";
import { MdiRadioButtonUnchecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonUnchecked";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { materialsConstants } from "constants/materials";
import { Formik } from "formik";
import { cx, dictToList } from "utilities";
import { validationSchema } from "./validationSchema";
import { materialsActions } from "api/manufacturing/materials/actions";

interface Props {
  close: () => void;
  materialDemand: any;
}

const initialValues: ManualMaterialStockUpdatePayload = {
  direction: MaterialStockUpdateDirection.IN,
  quantity: 0,
};

export const ManualMaterialStockUpdate = ({ close, materialDemand }: Props) => {
  const handleSubmit = materialsActions.usePatchMaterialStockManually(
    close,
    materialDemand.material,
  );

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Zmień stan materiału
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="mx-3">
              <div className="mb-3 d-flex align-items-baseline gap-2">
                <div className="d-flex flex-1">
                  <TextField.Form<ManualMaterialStockUpdatePayload>
                    containerClassName="flex-1"
                    label={`ilość/liczba (${materialDemand.material.unit.name})`}
                    name="quantity"
                    placeholder="ilość/liczba"
                    type="number"
                  />
                </div>
                <Typography fontSize="16" fontWeight="700">
                  {materialDemand.material.unit.shortName}
                </Typography>
              </div>

              <Typography fontSize="10" fontWeight="700" color="neutralBlack48" className="mb-1">
                Akcja:
              </Typography>
              <div className="d-flex gap-2 mb-3">
                {dictToList(materialsConstants.materialStockUpdateDirectionDict).map(
                  ({ key, value }) => (
                    <div
                      className="cursor-pointer"
                      key={key}
                      onClick={() => setFieldValue("direction", key)}
                    >
                      <Tag
                        label={value}
                        startIcon={
                          values.direction === key ? MdiRadioButtonChecked : MdiRadioButtonUnchecked
                        }
                        type={values.direction === key ? "filled" : "outlined"}
                        variant={values.direction === key ? "deepPurple50" : "quaternary"}
                      />
                    </div>
                  ),
                )}
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Zapisz
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
