import { Pagination, UUID } from "api/types";
import { manufacturingKeys } from "./keys";
import { ApiFetcher } from "hooks/createApiQuery";
import { queryFetch } from "apiConnectors/queryFetch";
import {
  IndexStages,
  ManufacturingEmployeeListItem,
  PostRecipe,
  Recipe,
  StageBoardDefaultAttributesKind,
  StageBoardDefaultAttributesKindPayload,
  StageComponent,
  StageComponentKind,
} from "./models";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";

const getManufacturingEmployees = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingEmployeeListItem>> => ({
  key: manufacturingKeys.employees.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/employees/items" + search,
    }),
});

const getIndexStages = (search: string): ApiFetcher<Pagination<IndexStages>> => ({
  key: manufacturingKeys.indexStages(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/manufacturing/stages/index-stages${search}`,
    }),
});

const getStageComponentsItems = (search: string): ApiFetcher<Pagination<StageComponent>> => ({
  key: manufacturingKeys.stageComponents(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/manufacturing/stage-components/items${search}`,
    }),
});

const postStageComponent = (data: {
  index: number;
  stage: UUID;
  recipe: UUID;
  kind: StageComponentKind;
}) =>
  queryFetch<Recipe>({
    method: "POST",
    url: "/manufacturing/stage-components/items",
    data,
  });

const deleteStageComponent = (stageComponentId: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/stage-components/items/" + stageComponentId,
  });

const patchStageComponent = (data: Assign<Partial<StageComponent>, { id: UUID }>) =>
  queryFetch<Recipe>({
    method: "PATCH",
    url: "/manufacturing/stage-components/items/" + data.id,
    data: parsePatchData(data),
  });

const getRecipes = (search: string): ApiFetcher<Pagination<Recipe>> => ({
  key: manufacturingKeys.recipes(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/manufacturing/recipes/items${search}`,
    }),
});

const getStageBoardAttributesKind = (
  search: string,
): ApiFetcher<Pagination<StageBoardDefaultAttributesKind>> => ({
  key: manufacturingKeys.settings.stageBoardAttributesKind(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/settings/stage-board-attributes-kind-to-group-by" + search,
    }),
});

const postStageBoardAttributesKind = (data: StageBoardDefaultAttributesKindPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/manufacturing/settings/stage-board-attributes-kind-to-group-by",
    data,
  });

const deleteStageBoardAttributesKind = (attributesKindId: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/settings/stage-board-attributes-kind-to-group-by/" + attributesKindId,
  });

const patchRecipe = (data: Assign<Partial<Recipe>, { id: UUID }>) =>
  queryFetch<Recipe>({
    method: "PATCH",
    url: "/manufacturing/recipes/items/" + data.id,
    data: parsePatchData(data),
  });

const deleteRecipe = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/recipes/items/" + id,
  });

const postRecipe = (data: PostRecipe) =>
  queryFetch<Recipe>({
    method: "POST",
    url: "/manufacturing/recipes/items",
    data,
  });

export const manufacturingApi = {
  deleteStageBoardAttributesKind,
  getManufacturingEmployees,
  getRecipes,
  getStageBoardAttributesKind,
  postRecipe,
  patchRecipe,
  deleteRecipe,
  getIndexStages,
  getStageComponentsItems,
  postStageComponent,
  deleteStageComponent,
  patchStageComponent,
  postStageBoardAttributesKind,
};
