import { materialsActions } from "api/manufacturing/materials/actions";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { TitleSection } from "./titleSection/TitleSection";
import { PanelHeader } from "./panelHeader/PanelHeader";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: materialDemand, error, isLoading } = materialsActions.useMaterial(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!materialDemand) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={materialDemand.id}>
        <PanelHeader />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <TitleSection />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
