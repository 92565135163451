import { materialsActions } from "api/manufacturing/materials/actions";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useQuery, useToggle } from "hooks";
import { cx, queryString } from "utilities";
import { useMaterialColumns } from "./useMaterialColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import { MaterialAvailabilityStatus, MaterialListItem } from "api/manufacturing/materials/models";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import styles from "./DemandAndAvailability.module.css";
import { RightPanel } from "./rightPanel/RightPanel";
import { materialsConstants } from "constants/materials";
import { materials } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/materials";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CreateMaterial } from "./createMaterial/CreateMaterial";

export const MaterialList = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);
  const {
    data: materialsDemandAndAvailability,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = materialsActions.useMaterials(search);

  const createMaterialModal = useToggle();

  const tableProps = useTableFeatureConnector({
    rows: materialsDemandAndAvailability,
    withDrawer: "materialsDemandAndAvailability",
    withPagination: { pagination },
  });

  const columns = useMaterialColumns();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        title={
          <Typography fontSize="20" fontWeight="700">
            Materiały - zapotrzebowanie i stan
          </Typography>
        }
        tabs={{
          list: materialsConstants.getMaterialsListTabs(),
          routesRoot: `manufacturing/${materials.url}`,
          urlSpan: "list",
        }}
        createButton={{ label: "Dodaj materiał", onClick: createMaterialModal.open }}
        viewLabel="DEMAND_AND_AVAILABILITY"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<MaterialListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
          overrides={() => {
            return {
              ...tableProps?.overrides?.(),
              row: row => {
                return {
                  className: cx(
                    {
                      [styles.veryHighStock]:
                        row.stock.stockStatus === MaterialAvailabilityStatus.VERY_HIGH,
                      [styles.veryLowStock]:
                        row.stock.stockStatus === MaterialAvailabilityStatus.VERY_LOW,
                    },
                    tableProps?.overrides?.().row?.(row).className,
                  ),
                };
              },
            };
          }}
        />
        <RightPanel />
        {createMaterialModal.isOpen && <CreateMaterial close={createMaterialModal.close} />}
      </div>
    </PageWrapper>
  );
};
