import { ISODate, ISODateTime, UUID } from "api/types";
import { UserWithInitials } from "api/users/models";
import { ManufacturingMaterialKind } from "./materials/models";
import { ImplementedBy } from "./units/models";
import { EmployedPlace } from "api/manufacturingNew/models";
import { CURRENCY_TYPE, EmployeeKindExtended } from "CONSTANTS";

export enum StageBoardAttributeKind {
  FABRIC = "FABRIC",
  SIZE = "SIZE",
}

export interface Recipe {
  id: UUID;
  material: {
    id: UUID;
    name: string;
    unit: string;
    kind: ManufacturingMaterialKind;
    category: { id: UUID; name: string } | null;
    externalId: string;
  };
  position: number;
  quantity: string;
  createdAt: ISODateTime;
  createdBy: number;
  updatedAt: ISODateTime;
  updatedBy: number;
  usedAsOutput: { id: UUID; name: string } | null;
  usedAsInput: { id: UUID; name: string } | null;
}

export interface PostRecipe {
  index: number;
  material: UUID | null;
  position: number;
  quantity: number;
}

export interface StageComponent {
  createdAt: ISODateTime;
  createdBy: number;
  id: UUID;
  index: number;
  kind: StageComponentKind;
  recipe: Recipe;
  stage: UUID;
}

export enum StageComponentKind {
  INPUT = "INPUT",
  OUTPUT = "OUTPUT",
}
export interface IndexStages {
  id: UUID;
  signature: string;
  name: string;
  stages: {
    code: string;
    id: number;
    isThisTheLastStage: boolean;
    position: number;
    name: string;
    stageId: UUID;
  }[];
}

export interface StageBoardDefaultAttributesKind {
  attributeKind: StageBoardAttributeKind;
  createdAt: ISODateTime;
  createdBy: UserWithInitials;
  id: UUID;
  schemaStage: {
    backgroundColor: string;
    code: string;
    id: UUID;
    implementedBy: ImplementedBy;
    name: string;
    textColor: string;
  };
}

export interface StageBoardDefaultAttributesKindPayload {
  attributeKind: StageBoardAttributeKind;
  schemaStage: UUID;
}

export enum Transaction {
  ADVANCE_PAYMENT = "ADVANCE_PAYMENT",
  WITHDRAWAL = "WITHDRAWAL",
}

export interface ManufacturingEmployeeListItem extends UserWithInitials {
  balance: number;
  dateOfEmployment: ISODate;
  email: string;
  employedPlace: EmployedPlace;
  isDeleted: boolean;
  kind: EmployeeKindExtended;
  number: string;
  phone: string;
  userId: number;
  wallet: {
    balance: number;
    id: UUID;
    latestPayment: {
      amount: number;
      createdAt: ISODateTime;
      currency: CURRENCY_TYPE;
      transactionType: Transaction;
    } | null;
  } | null;
}
